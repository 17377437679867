import React, { useEffect } from "react";
import { object, number } from "prop-types";

import StoryHeader from "../story-header";
import StoryElements from "../story-elements";
import StorySideBar from "../story-sidebar";
import StorySeparator from "../../atoms/story-separator";

import "./photo.m.css";
import { pushPageViewEvent } from "../../helper/datalayer-utils";

const PhotoStoryTemplate = ({ story, index }) => {
  useEffect(() => {
    const seenElements = new Set();

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.id;

        if (entry.isIntersecting && !seenElements.has(id)) {
          seenElements.add(id);

          window.history.replaceState(null, null, `#${id}`);
          pushPageViewEvent("photo_pageview", window.location.href, document?.title);
        }
      });
    });

    const images = document.querySelectorAll(".photo-image");

    if (images.length > 0) {
      images.forEach((image, index) => {
        image.setAttribute("id", index + 1);
        observer.observe(image);
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <StoryHeader story={story} />
            <StoryElements story={story} cards={story.cards} index={index} />
          </div>
        </div>
        <div className="side-story-section hide-mobile">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

PhotoStoryTemplate.propTypes = {
  story: object,
  index: number
};

const PhotoStory = (props) => {
  return <PhotoStoryTemplate story={props.story} index={props.index} />;
};

PhotoStory.propTypes = {
  story: object,
  index: number
};

export default PhotoStory;
