import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { SvgIconHandler } from "../svg-icon-hadler";
import CommentsModal from "../../story-templates/story-elements/comments-modal";
import { useSelector } from "react-redux";
import get from "lodash/get";

import "./comments-count.m.css";
import { addStoryPageViewToDataLayer } from "../../helper/datalayer-utils";

const CommentsCount = ({ story, showCommentText = true, renderPortal = false }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const coralRootUrl = useSelector((state) => get(state, ["qt", "config", "publisher-attributes", "coral", "root_url"], ""));

  useEffect(() => {
    const coralScript = document.createElement("script");
    coralScript.className = "coral-script";
    coralScript.src = `${coralRootUrl}/assets/js/count.js`;
    coralScript.defer = true;
    document.body.appendChild(coralScript);

    const triggerDataLayerEvent = (commentCount = 0) => {
      window.dataLayer = window.dataLayer || [];
      const eventAlreadyTriggered = window.dataLayer.some(
        (item) => item.event === "storyPageView"
      );

      if (!eventAlreadyTriggered) {
        addStoryPageViewToDataLayer(story, commentCount)
      }
    };

    const fetchCommentCount = () => {
      setTimeout(() => {
        const commentCountElement = document.querySelector(".coral-count");
        if (commentCountElement) {
          const commentCount = Number(commentCountElement.innerText) || 0;
          triggerDataLayerEvent(commentCount);
        } else {
          triggerDataLayerEvent()
          console.log("Comment count element not found.");
        }
      }, 750);
    };
    fetchCommentCount();

    return () => {
      document.body.removeChild(coralScript);
    };
  }, [coralRootUrl]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    localStorage.removeItem("isCommentsModalOpen");
  };
  return (
    <>
      <div styleName="comments-count" onClick={openModal}>
        <div styleName="wrapper">
          <span styleName="comment-icon">
            <SvgIconHandler type="comment" height="20px" width="20px" />
          </span>
          <span
            className="coral-count"
            data-coral-notext="true"
            data-coral-url={story.url}
            data-coral-id={story.id}
          ></span>
          {showCommentText && <span styleName="comments-text">Comments</span>}
        </div>
      </div>
      {renderPortal && typeof window === "object" ? (
        createPortal(
          <CommentsModal
            isOpen={modalOpen}
            onClose={closeModal}
            story={story}
            setModalOpen={setModalOpen}
          />,
          document.body
        )
      ) : (
        <CommentsModal
          isOpen={modalOpen}
          onClose={closeModal}
          story={story}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
};

export default CommentsCount;
