import React, { useEffect } from "react";
import { object, number } from "prop-types";

import StoryHeader from "../story-header";
import StoryElements from "../story-elements";
import StorySideBar from "../story-sidebar";
import StorySeparator from "../../atoms/story-separator";

import "./text-story.m.css";

const TextStoryTemplate = ({ story, index, isWebView = false }) => {
  const isMovieReview = story["story-template"] === "movie-review";
  const isGadgetReview = story["story-template"] === "gadget-review";
  const isSponsoredStory = story["story-template"] === "sponsored";
  const isBookReview = story["story-template"] === "book-review";
  const isFactCheck = story["story-template"] === "fact-check";
  const isSubhashita = story["story-template"] === "subhashita";
  const isNewTextStory = story["story-template"] === "text-new" || story["story-template"] === "news-briefs";

  useEffect(() => {
    if (!isWebView && story["story-template"] === "news-briefs") {
      const storyCards = Array.from(document.querySelectorAll(".story-card")).map((card, index) => {
        card.setAttribute("id", index + 1);
        return card;
      });

      const seenElements = new Set();

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((card) => {
          const id = card.target.id;
          if (card.isIntersecting && !seenElements.has(id)) {
            seenElements.add(id);

            window.history.replaceState(null, null, `#${id}`);
            window.dataLayer.push({
              event: "news_briefs_pageview",
              page: {
                url: window.location.href,
                title: document?.title
              }
            });
          }
        });
      });

      storyCards.forEach((card) => {
        observer.observe(card);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);
  return (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <StoryHeader story={story} isSponsoredStory={isSponsoredStory} />
            <StoryElements
              story={story}
              cards={isSubhashita ? story.cards.slice(1) : story.cards}
              index={index}
              isFactCheck={isFactCheck}
              isMovieReview={isMovieReview}
              isGadgetReview={isGadgetReview}
              isSponsoredStory={isSponsoredStory}
              isBookReview={isBookReview}
              isNewTextStory={isNewTextStory}
            />
          </div>
        </div>
        <div className="side-story-section hide-mobile">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

TextStoryTemplate.propTypes = {
  story: object,
  index: number
};

const TextStory = ({ story, index }) => {
  return <TextStoryTemplate story={story} index={index} />;
};

TextStory.propTypes = {
  story: object,
  index: number
};

export default TextStory;
