import React, { useEffect } from "react";
import { object, number, bool } from "prop-types";

import StoryHeader from "../story-header";
import StoryElements from "../story-elements";
import StorySideBar from "../story-sidebar";

import "./listcle.m.css";
import { pushPageViewEvent } from "../../helper/datalayer-utils";

const ListcleStoryTemplate = ({ story, index, isWebView = false }) => {
  useEffect(() => {
    if (!isWebView) {
      const storyCards = Array.from(document.querySelectorAll(".story-card"))
        .filter((card) => card.querySelector(".story-element-title"))
        .map((card, index) => {
          card.setAttribute("id", index + 1);
          return card;
        });

      const seenElements = new Set();

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((card) => {
            const id = card.target.id;
            if (card.intersectionRatio >= 0.05 && !seenElements.has(id)) {
              seenElements.add(id);

              window.history.replaceState(null, null, `#${id}`);
              pushPageViewEvent("listicle_pageview", window.location.href, document?.title);
            }
          });
        },
        {
          threshold: 0.05
        }
      );

      storyCards.forEach((card) => {
        observer.observe(card);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return isWebView ? (
    <div styleName="story-wrapper">
      <div className="story-section">
        <StoryElements story={story} cards={story.cards} index={index} isImmersiveStory={true} />
      </div>
    </div>
  ) : (
    <>
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <StoryHeader story={story} />
            <StoryElements story={story} cards={story.cards} index={index} />
          </div>
        </div>

        <div className="side-story-section hide-mobile">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

ListcleStoryTemplate.propTypes = {
  story: object,
  index: number,
  isWebView: bool
};

const ListcleStory = (props) => {
  return <ListcleStoryTemplate story={props.story} index={props.index} isWebView={props.isWebView} />;
};

ListcleStory.propTypes = {
  story: object,
  index: number
};

export default ListcleStory;
